/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useEffect } from 'react';
import { useMediaQueryForMobile } from '../../hooks/useMediaQueryForMobile';
import { handleDownLoad } from '../SellerboxAppPageV1';
import { colors } from '../../style';
import { useStoreValue } from '../../contexts';

import FinishHandImg from '../../assets/images/FinishHandImg.png';

const MARKET_NAME_MAP = {
  naver: '커머스 솔루션',
  shopby: '샵바이',
};

const subTitleRender = (marketName: string) => {
  switch (marketName) {
    case 'naver':
      return (
        <div
          style={{
            marginTop: 36,
            color: '#04AF36',
            fontSize: 14,
            fontWeight: 500,
            lineHeight: '140%',
          }}
        >
          커머스 솔루션 사용이 시작되었습니다.
        </div>
      );
    case 'shopby':
      return (
        <div
          style={{
            marginTop: 36,
            color: '#00C9C9',
            fontSize: 14,
            fontWeight: 500,
            lineHeight: '140%',
          }}
        >
          샵바이 연동이 완료되었어요!
        </div>
      );
    case 'cafe24':
      return (
        <div
          style={{
            marginTop: 36,
            color: '#1a54f5',
            fontSize: 14,
            fontWeight: 500,
            lineHeight: '140%',
          }}
        >
          cafe24 연동이 완료되었어요!
        </div>
      );
    case 'imweb':
      return (
        <div
          style={{
            marginTop: 36,
            color: '#356BF6',
            fontSize: 14,
            fontWeight: 500,
            lineHeight: '140%',
          }}
        >
          아임웹 연동이 완료되었습니다.
        </div>
      );
    default:
      return null;
  }
};

const titleRender = (isMobile: boolean, isSignup: boolean, marketName: string) => {
  return (
    <div style={{ display: 'flex' }}>
      <div
        style={{
          marginTop: 6,
          color: '#1A1A1A',
          fontSize: 24,
          fontWeight: 800,
          lineHeight: '140%',
        }}
      >
        {isSignup ? (
          <span>
            셀러박스 앱 다운로드 후
            <br />
            솔루션을 사용해 보세요
          </span>
        ) : (
          <span>
            셀러박스 앱에서
            <br />
            {`${MARKET_NAME_MAP[marketName] || ''} 데이터를 확인해 보세요!`}
          </span>
        )}
      </div>
      {!isMobile && isSignup && (
        <img src="../images/QrDownloadByNaver.png" style={{ width: 78, marginLeft: 40 }} alt="" />
      )}
    </div>
  );
};

const MarketRegisterFinish = () => {
  const isMobile = useMediaQueryForMobile();
  const { isSignup } = useStoreValue();

  const enteredByMarketName = localStorage.getItem('enteredByMarketName') || '';
  // 구독 끝나고 최종으로 보여지는 페이지 ( 기능은 없고 앱 다운로드 QR, 버튼만 존재 )

  useEffect(() => () => localStorage.clear());

  return (
    <div
      style={{
        width: '100vw',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: colors.gray.GR150,
      }}
    >
      <a id="goToApp" style={{ display: 'none' }} href="sellerbox://" />
      <div
        style={{
          padding: 20,
          paddingBottom: 100,
          height: '100%',
          minHeight: '100vh',
        }}
      >
        <img
          src="../images/naverSolutionStartLogo.png"
          style={{ height: 20, marginTop: 12 }}
          alt=""
        />
        {subTitleRender(enteredByMarketName)}
        {titleRender(isMobile, isSignup, enteredByMarketName)}
        <div
          style={{
            marginTop: 36,
            color: '#1A1A1A',
            fontSize: 16,
            fontWeight: 500,
            lineHeight: '150%',
          }}
        >
          <span style={{ color: '#FF7F00' }}>셀러박스,</span> 성공에 필요한
          <br />
          모든 데이터를 확인하세요.
        </div>
        {!isMobile && isSignup && (
          <div className="flexRowWrapper" style={{ gap: 10, width: 288, marginTop: 24 }}>
            <img
              className="pointer"
              src={'../images/mobile-home-google-download.png'}
              style={{ width: 'calc(50% - 5px)' }}
              onClick={() => {
                handleDownLoad('android', true);
              }}
              alt=""
            />
            <img
              className="pointer"
              src={'../images/mobile-home-apple-download.png'}
              style={{ width: 'calc(50% - 5px)' }}
              onClick={() => {
                handleDownLoad('ios', true);
              }}
              alt=""
            />
          </div>
        )}
        <div
          style={{
            marginTop: isMobile ? 7 : 38,
            width: '100%',
          }}
        >
          <img src={FinishHandImg} style={{ width: isMobile ? 325 : 378 }} alt="" />
        </div>
        {isMobile && isSignup && (
          <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <div className="flexRowWrapper" style={{ gap: 10, width: 288, marginTop: 14 }}>
              <img
                className="pointer"
                src={'../images/mobile-home-google-download.png'}
                style={{ width: 'calc(50% - 5px)' }}
                onClick={() => {
                  handleDownLoad('android', true);
                }}
                alt=""
              />
              <img
                className="pointer"
                src={'../images/mobile-home-apple-download.png'}
                style={{ width: 'calc(50% - 5px)' }}
                onClick={() => {
                  handleDownLoad('ios', true);
                }}
                alt=""
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default MarketRegisterFinish;
