import React, { useState } from 'react';
import { WholePageWrapper } from '../SellerboxAppPageV1';
import useWindowSize from '../../hooks/useWindowSize';
import { Button } from '@mui/material';
import { useHistory, useLocation } from 'react-router';
import { colors } from '../../style';
import font from '../../style/font';
import useAlertModal from '../../hooks/useAlertModal';
import {
  SetCafe24CommerceCode,
  SetImwebCommerceCode,
  SetNaverCommerceToken,
  SetShopbyCommerceCode,
  UserSignInByPhoneNo,
  UserSignUpByPhoneNo,
} from '../../controller/api';
import { useStoreAction, useStoreValue } from '../../contexts';
import { marketFinishUrl, smartStoreGuideUrl } from '../../constants/urls';

const RegisterTerms = () => {
  //약관동의 이후 회원가입 프로세스 진행
  const { naverToken, shopbyToken } = useStoreValue();
  const { setIsSignup } = useStoreAction();
  const requestAlertModal = useAlertModal();
  const dimensions = useWindowSize();
  const history = useHistory();
  const verificationToken = new URLSearchParams(window.location.search).get('verificationToken');
  const cafe24AuthorizationCode = new URLSearchParams(window.location.search).get(
    'cafe24AuthorizationCode'
  );
  // imweb 인증 코드
  const imwebCode = new URLSearchParams(window.location.search).get('imwebCode');
  const tmpAgreements = localStorage.getItem('agreements');
  const [registerLoading, setRegisterLoading] = useState(false);
  const enteredByMarketName = localStorage.getItem('enteredByMarketName') || '';
  // cafe24 전용
  const mallId = localStorage.getItem('mallId') || '';

  //약관
  const [agreements, setAgreements] = useState(
    tmpAgreements
      ? JSON.parse(tmpAgreements)
      : [
          {
            title: '서비스 이용 약관 동의',
            isEssential: true,
            type: 'serviceTerms',
            isAgreed: false,
          },
          {
            title: '개인정보 수집 및 이용동의',
            isEssential: true,
            type: 'privacyTerms',
            isAgreed: false,
          },
          {
            title: '이벤트/프로모션 정보 선택 동의',
            isEssential: false,
            type: 'eventTerms',
            isAgreed: false,
          },
        ]
  );

  //약관을 모두 동의했는지 확인하는 flag (agreements state이 변경될때마다 계속 갱신됨)
  const isValidAgreements = agreements.reduce(
    (acc, cur, curIndex) => (cur.isAgreed === false && curIndex <= 1 ? false : acc),
    true
  );

  const handleRegister = () => {
    //회원가입 로직
    setRegisterLoading(true);
    let userName: string;
    UserSignUpByPhoneNo({
      VerificationToken: verificationToken, //미리 진행한 휴대폰 인증 정보가 있음
      MarketSyncAgreeYn: 'Y',
      AdAgreeYn: agreements[2].isAgreed === false ? 'N' : 'Y',
      SignUpPlatform: enteredByMarketName,
    })
      .then((res) => {
        const { message } = res.data;
        if (message === 'OK') {
          // 회원가입 여부 상태관리 finish 분기처리 하기 위해서
          setIsSignup(true);
          //회원가입 되면 로그인까지 진행 (유저 닉네임을 알기 위해서)
          UserSignInByPhoneNo({
            VerificationToken: verificationToken,
            OsType: 'web',
            CurrentVersion: 'naver-commerce-solution-market',
          })
            .then((res) => {
              const { data, message } = res.data;
              if (message === 'OK') {
                switch (enteredByMarketName) {
                  case 'naver':
                    SetNaverCommerceToken({
                      CplatToken: data[0].CplatToken,
                      NaverCommerceToken: naverToken,
                    })
                      .then((res) => {
                        const { code, message } = res.data;
                        if (code === '200') {
                          //구독이 모두 끝나고 마무리 안내 페이지로 페이지 전환 (유저 닉네임과 함께, 메니저 초대 방식등 설명이 있는 페이지)
                          history.push(`${smartStoreGuideUrl}?userName=${userName}`);
                        } else {
                          requestAlertModal({
                            title: '',
                            description: message,
                          });
                        }
                      })
                      .catch((err) => {
                        requestAlertModal({
                          title: '',
                          description: '서버와 통신이 원활하지 않습니다.\n다시 시도해 주세요.',
                        });
                      })
                      .finally(() => {
                        setRegisterLoading(false);
                      });
                    break;
                  case 'shopby':
                    SetShopbyCommerceCode({
                      cplatToken: data[0].CplatToken,
                      shopbyAuthorizationCode: shopbyToken,
                    })
                      .then(() => {
                        history.push(marketFinishUrl);
                      })
                      .catch(() => {
                        setRegisterLoading(false);
                        requestAlertModal({
                          title: '',
                          description: '서버와 통신이 원활하지 않습니다.\n다시 시도해 주세요.',
                        });
                      });
                    break;

                  case 'cafe24':
                    SetCafe24CommerceCode({
                      cplatToken: data[0].CplatToken,
                      authorizationCode: cafe24AuthorizationCode,
                      mallId,
                    }).then((res) => {
                      const { code, message } = res.data;

                      if (code === '200') {
                        history.push(marketFinishUrl);
                      } else {
                        requestAlertModal({
                          title: '',
                          description: message,
                        });
                      }
                    });
                    break;

                  case 'imweb':
                    SetImwebCommerceCode({
                      CplatToken: data[0].CplatToken,
                      ImwebAuthorizationCode: imwebCode,
                    }).then((res) => {
                      const { code, message } = res.data;
                      if (code === '200') {
                        history.push(marketFinishUrl);
                      } else {
                        requestAlertModal({
                          title: '',
                          description: message,
                        });
                      }
                    });
                    break;
                  default:
                    break;
                }

                userName = data[0].StoreName;
              } else {
                setRegisterLoading(false);
                requestAlertModal({
                  title: '',
                  description: message,
                });
              }
            })
            .catch((err) => {
              setRegisterLoading(false);
              requestAlertModal({
                title: '',
                description: '서버와 통신이 원활하지 않습니다.\n다시 시도해 주세요.',
              });
            });
        } else {
          setRegisterLoading(false);
          requestAlertModal({
            title: '',
            description: message,
          });
        }
      })
      .catch((err) => {
        setRegisterLoading(false);
        requestAlertModal({
          title: '',
          description: '서버와 통신이 원활하지 않습니다.\n다시 시도해 주세요.',
        });
      });

    // window.open("https://sell.smartstore.naver.com/#/member/auth?interlockType=solution_auth&returnUrl=https://solution.smartstore.naver.com/ko/oauth/redirect/solutionId=10XxRcUYSy4N7fiZ5BYNMh");
    // let userName: string = ((Math.random() * 1000).toFixed(0)).toString() + "하마"

    //console.log(verificationToken)  기존회원 가입scene 진행 +  구독완료 페이지로 이동
  };
  return (
    <WholePageWrapper style={{ justifyContent: 'flex-start', boxSizing: 'border-box' }}>
      <div
        style={{
          backgroundColor: colors.gray.GR100,
          width: dimensions.width,
          height: dimensions.hieght,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            backgroundColor: '#FFFFFF',
            boxSizing: 'border-box',
            padding: '0px 20px',
            paddingTop: 20,
            maxWidth: '600px',
            height: '100%',
            width: '100%',
          }}
        >
          <img
            src="svg/headerLeftIcon.svg"
            style={{
              marginBottom: 'calc( ( 100vh * 0.1 ) - 40px )',
            }}
            onClick={() => {
              history.goBack();
            }}
            alt="headerLeftIcon"
          />
          <div
            style={{
              ...font.Title2B20,
              color: colors.gray.GR900,
              marginBottom: 8,
            }}
          >
            서비스 이용 약관
          </div>
          <div
            style={{
              ...font.Body6M14,
              color: colors.gray.GR750,
              marginBottom: 22,
            }}
          >
            셀러박스 서비스 이용을 위한 약관에 동의해 주세요.
          </div>

          {agreements.map((agreement, agreementIndex) => (
            <div
              key={`${agreement} ${agreementIndex}`}
              style={{ display: 'flex', cursor: 'pointer', marginBottom: 20 }}
            >
              <div
                style={{
                  display: 'flex',
                  cursor: 'pointer',
                  alignItems: 'center',
                }}
                onClick={() => {
                  let tmpAgreements = agreements.slice();
                  tmpAgreements[agreementIndex].isAgreed = !tmpAgreements[agreementIndex].isAgreed;
                  setAgreements(tmpAgreements);
                  localStorage.setItem('agreements', JSON.stringify(tmpAgreements));
                }}
              >
                {agreement.isAgreed ? (
                  <img src="svg/enabledCheckbox.svg" alt="enabledCheckbox" />
                ) : (
                  <img src="svg/disabledCheckbox.svg" alt="disabledCheckbox" />
                )}

                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginLeft: 10,
                  }}
                >
                  <div
                    style={{
                      ...font.Caption1M12,
                      color: colors.gray.GR750,
                      marginRight: 4,
                    }}
                  >
                    {agreement.isEssential ? '[필수]' : '[선택]'}
                  </div>
                  <div
                    style={{
                      ...font.Body2M16,
                      color: colors.gray.GR750,
                      marginRight: 8,
                    }}
                  >
                    {agreement.title}
                  </div>
                </div>
              </div>
              <div
                style={{
                  ...font.Body6M14,
                  lineHeightStep: '24px',
                  color: colors.gray.GR600,
                  textUnderlineOffset: 4,
                  textDecoration: 'underline',
                  textDecorationColor: colors.gray.GR600,
                }}
                onClick={(e) => {
                  history.push(
                    `/termsDetail?termsType=${agreement.type}&agreements=${JSON.stringify(
                      agreements
                    )}`
                  );
                  e.preventDefault();
                }}
              >
                전문보기
              </div>
            </div>
          ))}

          <div
            style={{
              position: 'fixed',
              bottom: 30,
              width: '100%',
            }}
          >
            <div
              style={{
                width: '100%',
                maxWidth: 600,
                boxSizing: 'border-box',
                paddingRight: 40,
              }}
            >
              <Button
                disabled={!isValidAgreements || registerLoading}
                onClick={handleRegister}
                style={{
                  width: '100%',
                  backgroundColor: !isValidAgreements ? colors.gray.GR200 : colors.orange.OR500,
                  color: colors.gray.White,
                  borderRadius: 16,
                  padding: '14px 0px',
                  ...font.Body1S16,
                }}
              >
                다음
              </Button>
            </div>
          </div>
        </div>
      </div>
    </WholePageWrapper>
  );
};

export default RegisterTerms;
