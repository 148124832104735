import { styled, ThemeProvider } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { Footer } from './components/Footer';
import {
  appDownloadUrl,
  clientsUrl,
  companyUrl,
  ContactUrl,
  noticeUrl,
  partnerUrl,
  PrivacyPolicy,
  recruitmentUrl,
  serviceUrl,
  TermsOfUse,
  MarketingAgree,
  signIn,
  logout,
  naverRegisterStart,
  registerTerms,
  termsDetail,
  smartStoreGuideUrl,
  naverRegisterToken,
  marketFinishUrl,
  promotion_3o3,
  sellkeyUrl,
  priceUrl,
} from './constants/urls';
import AppDownLoadPage from './pages/AppDownLoadPage';
import { CompanyIntroducePage } from './pages/CompanyIntroducePage';
import { NoticePage } from './pages/NoticePage';
import { RecruitmentPage } from './pages/RecruitmentPage';
import { TermsOfUsePage } from './pages/TermsOfUsePage';
import { MarketingAgreement } from './pages/MarketingAgreement';
import { MainTheme } from './themes/MainTheme';
import { FAQPage } from './pages/FAQPage';
import { ContactPage } from './pages/ContactPage';
import { PartherShipPage } from './pages/PartnerShipPage';
import PrivacyMainPage from './pages/PrivacyMainPage';
import { NoticePageTest } from './pages/NoticePageTest';

import { Card } from './pages/modulePage/Card/Card';
import { Market } from './pages/modulePage/Market/Market';
import { SellkeyMarket } from './pages/modulePage/Market/SellkeyMarket';
import { KakaoLogin } from './pages/modulePage/KakaoLogin/KakaoLogin';
import { DataVoucher } from './pages/DataVoucher';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'core-js';

import { useMediaQueryForMobile } from './hooks/useMediaQueryForMobile';
import { SellerboxPromotionPage } from './pages/SellerboxPromotionPage';
import Kcb from './pages/modulePage/Company/Kcb';
import Danal from './pages/modulePage/Company/Company';
import SignIn from './pages/marketRegister/SignIn';
import RegisterTerms from './pages/marketRegister/RegisterTerms';
import { UserSignInByPass } from './controller/api';
import AlertModal from './components/Modal/AlertModal';
import Logout from './pages/Logout';
import NaverRegisterStart from './pages/marketRegister/NaverRegisterStart';
import TermsDetail from './pages/marketRegister/TermsDetail';
import SmartStoreGuidePage from './pages/marketRegister/SmartStoreGuidePage';
import NaverRegisterToken from './pages/marketRegister/NaverRegisterToken';
import MarketRegisterFinish from './pages/marketRegister/Finish';
import { SellerboxAppPage } from './pages/SellerboxAppPage/SellerboxAppPage';
import GuardForMarketRegister from './components/GuardForMarketRegister';
import { useStoreAction, useStoreValue } from './contexts';
import Register from './pages/marketRegister/Register';
import Cafe24RegisterStart from './pages/marketRegister/Cafe24RegisterStart';
import { MenuBar } from './components/MenuBar/MenuBar';
import SellkeyPage from './pages/SellkeyPage/SellkeyPage';
import PricePage from './pages/PricePage/PricePage';
import ImwebRegisterStart from './pages/marketRegister/ImwebRegisterStart';
const getItemWithExpireTime = (keyName) => {
  const objString = window.localStorage.getItem(keyName);
  if (!objString) {
    return null;
  }
  const obj = JSON.parse(objString);
  if (Date.now() > obj.expire) {
    window.localStorage.removeItem(keyName);
    return null;
  }
  return obj.value;
};

function App() {
  const [isAutoSignInLoading, setIsAutoSignInLoading] = useState(false);
  const { isEnteredByNaver, isModuleMatch, alertInfo } = useStoreValue();
  const { setUserInfo } = useStoreAction();
  const isMobile = useMediaQueryForMobile();

  useEffect(() => {
    if (isEnteredByNaver && isModuleMatch) {
      document.body.style.overflowX = 'hidden';
    } else {
      document.body.style.overflowX = 'scroll';
    }
  }, [isEnteredByNaver, isModuleMatch]);

  useEffect(() => {
    let token = getItemWithExpireTime('userToken');
    if (token) {
      UserSignInByPass({
        CplatToken: token,
        OsType: 'web',
        CurrentVersion: 'naver-commerce-solution-market',
      })
        .then((res) => {
          const { data, code } = res.data;
          if (code === '200') {
            setUserInfo(data[0]);
          } else {
            window.localStorage.removeItem('userToken');
          }
        })
        .finally(() => {
          setIsAutoSignInLoading(false);
        });
    } else {
      setIsAutoSignInLoading(false);
    }
  }, []);

  useEffect(() => {
    if (isMobile) {
      window.scrollTo(0, 0);
    }
  }, [isMobile]);

  let MainContents = isMobile || isEnteredByNaver ? MobileMainContents : PcMainContents;
  return isAutoSignInLoading ? null : (
    <BrowserRouter>
      <ThemeProvider theme={MainTheme}>
        <AlertModal
          title={alertInfo.title}
          description={alertInfo.description}
          isOpen={alertInfo.isAlertModalOpen}
          action={alertInfo.action}
          onClose={alertInfo.onClose}
        />
        <div style={{ boxSizing: 'border-box', width: '100%' }}>
          <MainContents id="hi3">
            {!isModuleMatch && <MenuBar />}
            <Switch>
              <Route path={'/module/company/:company/:type'}>
                <Danal />
              </Route>
              <Route path={'/kcb/:type'}>
                <Kcb />
              </Route>

              <Route path={'/kcb_promotion'}>
                <SellerboxPromotionPage />
              </Route>
              <Route path={serviceUrl}>
                <SellerboxAppPage />
              </Route>
              <Route path={sellkeyUrl}>
                <SellkeyPage />
              </Route>
              <Route path={priceUrl}>
                <PricePage />
              </Route>
              {/* <Route path={promotion_3o3}>
                <SellerboxAppPage />
              </Route> */}
              <Route path="/market/register/cafe24">
                <Cafe24RegisterStart />
              </Route>
              <Route path="/market/register/imweb">
                <ImwebRegisterStart />
              </Route>
              <Route path="/market/register/:marketName">
                <Register />
              </Route>
              <Route path={naverRegisterStart}>
                <NaverRegisterStart />
              </Route>
              <Route path={naverRegisterToken}>
                <NaverRegisterToken />
              </Route>
              <Route path={signIn}>
                <GuardForMarketRegister>
                  <SignIn />
                </GuardForMarketRegister>
              </Route>
              <Route path={registerTerms}>
                <GuardForMarketRegister>
                  <RegisterTerms />
                </GuardForMarketRegister>
              </Route>
              <Route path={termsDetail}>
                <GuardForMarketRegister>
                  <TermsDetail />
                </GuardForMarketRegister>
              </Route>
              <Route path={smartStoreGuideUrl}>
                <SmartStoreGuidePage />
              </Route>

              <Route path={marketFinishUrl}>
                <MarketRegisterFinish />
              </Route>

              <Route path={logout}>
                <Logout />
              </Route>
              <Route path={companyUrl}>
                <CompanyIntroducePage />
              </Route>
              <Route path={appDownloadUrl}>
                <AppDownLoadPage />
              </Route>
              <Route path={recruitmentUrl}>
                <RecruitmentPage />
              </Route>
              <Route path={TermsOfUse}>
                <TermsOfUsePage />
              </Route>
              <Route path={MarketingAgree}>
                <MarketingAgreement />
              </Route>
              <Route path={PrivacyPolicy}>
                <PrivacyMainPage />
              </Route>
              <Route path={'/clients/notice'}>
                <NoticePage />
              </Route>
              <Route path={partnerUrl}>
                <PartherShipPage />
              </Route>
              <Route path={clientsUrl}>
                <Route path={'/clients/faq'}>
                  <FAQPage />
                </Route>
                <Route path={noticeUrl}>
                  <NoticePageTest />
                </Route>
                <Route path={ContactUrl}>
                  <ContactPage />
                </Route>
              </Route>
              <Route path={'/module/card'}>
                <Card />
              </Route>

              <Route path={'/dataVoucher'}>
                <DataVoucher />
              </Route>

              <Route path={'/module/market'}>
                <Market />
              </Route>

              <Route path={'/module/sellkeyMarket/:store'}>
                <SellkeyMarket />
              </Route>

              <Route path={'/module/kakaoLogin'}>
                <KakaoLogin />
              </Route>

              <Redirect to={serviceUrl} />
            </Switch>
            {!isModuleMatch && <Footer />}
          </MainContents>
        </div>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;

const PcMainContents = styled('div')({
  minWidth: '1440px',
});

const MobileMainContents = styled('div')({
  minWidth: '100%',
  width: '100%',
});

export const ContainerFrame = styled('div')(({ theme }: any) => ({
  maxWidth: 1200,
  width: 'calc(100% - 340px)',
  left: 0,
  right: 0,
  marginLeft: 'auto',
  marginRight: 'auto',
  [theme.breakpoints.down('sm')]: {
    width: 'calc(100% - 40px)',
  },
}));
