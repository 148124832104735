import { useLocation } from 'react-router';
import React, { useEffect } from 'react';
import { WholePageWrapper } from '../SellerboxAppPageV1';

const ImwebRegisterStart = () => {
  const location = useLocation();
  const queryString = new URLSearchParams(location.search);
  const siteCode = queryString.get('siteCode') || '';

  const handleAuthorize = (siteCode: string) => {
    fetch(
      `https://openapi.imweb.me/oauth2/authorize?responseType=code&clientId=1c01062b-36aa-4aa2-a3fc-50dd5cff11b6&redirectUri=https://sellerbox.io/signIn&scope=site-info:write member-info:read promotion:write community:write product:read order:write&siteCode=${siteCode}`
    )
      .then((res) => {
        console.log(res);
        const { url } = res;
        if (url) {
          window.open(url, '_self');
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    localStorage.setItem('enteredByMarketName', 'imweb');
    localStorage.setItem('siteCode', siteCode);
    if (siteCode) {
      handleAuthorize(siteCode);
    }
  }, []);

  return <WholePageWrapper style={{ justifyContent: 'flex-start', boxSizing: 'border-box' }} />;
};

export default ImwebRegisterStart;
